import React from 'react';
import { X } from 'lucide-react';
import './styles/DiscordLookup.css';

const formatBackendData = (lookupData) => {
  if (!lookupData) return null;
  return {
    bannerImage: lookupData.banner?.link || '',
    bannerColor: lookupData.banner?.color || '#5865F2',
    avatar: lookupData.avatar?.link || 'https://cdn.discordapp.com/embed/avatars/0.png',
    badges: (lookupData.badges || []).map(badge => ({
      iconUrl: `https://cdn.discordapp.com/badge-icons/${badge}.png`,
      tooltip: badge,
    })),
    username: lookupData.username || 'Unknown User',
    discriminator: lookupData.global_name || '',
    userLabel: lookupData.premium_type === "None" ? "" : lookupData.premium_type,
    memberSince: lookupData.created_at || 'Unknown',
    roles: [],
    acceptMessages: true,
    button: {
      text: 'View Profile',
      url: `https://discord.com/users/${lookupData.id}`
    },
    userId: lookupData.id,
    aboutMe: `Discord User card of ${lookupData.username} by SEEKBASE.`,
  };
};

const DiscordLookup = ({ lookupData }) => {
  const data = formatBackendData(lookupData);

  if (!data) {
    return (
      <div className="discord-card">
        <div className="profile-container">
          <p className="error-message">No Discord data available</p>
        </div>
      </div>
    );
  }

  return (
    <div className="discord-card">
      {/* Banner */}
      <div className="banner-container">
        {data.bannerImage ? (
          <div 
            className="banner-image"
            style={{ backgroundImage: `url(${data.bannerImage})` }}
          />
        ) : (
          <div 
            className="banner-color"
            style={{ backgroundColor: data.bannerColor }}
          />
        )}
      </div>

      {/* Profile Content */}
      <div className="profile-container">
        {/* Avatar & Badges */}
        <div className="avatar-badges-container">
          <div className="avatar-wrapper">
            <a 
              href={data.userId ? `https://discord.com/users/${data.userId}` : '#'}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={data.avatar}
                alt={`${data.username}'s avatar`}
                className="avatar-image"
              />
              <div className="avatar-overlay">
                <span className="see-profile-text">See Profile</span>
              </div>
            </a>
          </div>

          {data.badges && data.badges.length > 0 && (
            <div className="badges-container">
              {data.badges.map((badge, index) => (
                <div key={index} className="badge-item">
                  <img
                    src={badge.iconUrl}
                    alt={badge.tooltip}
                    className="badge-image"
                  />
                  <div className="badge-tooltip">
                    {badge.tooltip}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* User Info */}
        <div className="user-info-container">
          <div className="username-container">
            <span className="username">
              {data.username}
              {data.discriminator && (
                <span className="discriminator">#{data.discriminator}</span>
              )}
            </span>
            {data.userLabel && (
              <span className="user-label">{data.userLabel}</span>
            )}
          </div>

          <hr className="divider" />

          {data.button && (
            <a
              href={data.button.url}
              target="_blank"
              rel="noopener noreferrer"
              className="profile-button"
            >
              {data.button.text}
            </a>
          )}

          {/* About Me */}
          <div className="section">
            <h3 className="section-title-discord">About Me</h3>
            <p className="section-content">
              {data.aboutMe}
            </p>
          </div>

          {/* Member Since */}
          {data.memberSince && (
            <div className="section">
              <h3 className="section-title-discord">Member Since</h3>
              <p className="section-content">{data.memberSince}</p>
            </div>
          )}

          {/* Roles */}
          {data.roles && data.roles.length > 0 && (
            <div className="section">
              <h3 className="section-title-discord">Roles</h3>
              <div className="roles-container">
                {data.roles.map((role, index) => (
                  <div key={index} className="role-item">
                    <div
                      className="role-color"
                      style={{ backgroundColor: role.color }}
                    >
                      <div className="role-remove">
                        <X size={10} />
                      </div>
                    </div>
                    <span>{role.name}</span>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Note */}
          <div className="section">
            <h3 className="section-title-discord">Note</h3>
            <textarea
              placeholder="Click to add a note"
              className="note-textarea"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiscordLookup;