import { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { toast } from 'react-toastify';
import supabase from '../supabase';
import { iconMap } from '../utils/iconMap';

const SECRET_KEY = '79c47167d5134a10a6320c881a3266030ee617ac0cf90c10210094c0cdbc5cb4';
const CREDITS_COST = 10;
const MAX_NODES = 200;
const MAX_DEPTH = 15;
const MAX_CHILDREN_PER_NODE = 25;

export const useGraphSearch = ({ setNodes, setEdges }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [nodeLookup, setNodeLookup] = useState({});
  const navigate = useNavigate();
  
  const currentIdRef = useRef(1);
  const infosPoolRef = useRef(new Set());

  const validateSearchQuery = useCallback((query) => {
    if (!query || typeof query !== 'string') return false;
    if (query.trim().length < 1) return false;
    if (query.length > 100) {
      toast.error("Search query too long (max 100 characters)");
      return false;
    }
    return true;
  }, []);

  const deductCredits = useCallback(async () => {
    try {
      if (!token) {
        throw new Error("No authentication token found");
      }

      const response = await axios.post(
        'https://api.seekbase.shop/api/v1/user/deduct-credits',
        {},
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      if (response.status === 200 && response.data) {
        setUser(prev => ({
          ...prev,
          user_data: {
            ...prev.user_data,
            credits: response.data.new_credits
          }
        }));
        return true;
      }
      throw new Error("Invalid response from credit deduction");
    } catch (error) {
      console.error("Error deducting credits:", error);
      toast.error(error.response?.data?.message || "Failed to deduct credits");
      return false;
    }
  }, [token]);

  const generateHMAC = useCallback((query) => {
    const timestamp = Math.floor(Date.now() / 1000);
    const payload = `${query}|${timestamp}`;
    const hmac = CryptoJS.HmacSHA256(payload, SECRET_KEY).toString(CryptoJS.enc.Hex);
    return { signature: hmac, timestamp };
  }, []);

  const countKeys = useCallback((data) => {
    if (!data || typeof data !== 'object') return 0;
    return Object.keys(data)
      .filter(key => key !== 'raw')
      .reduce((total, key) => {
        const value = data[key];
        return total + (value && typeof value === 'object' ? Object.keys(value).length : 0);
      }, 0);
  }, []);

  const createNode = useCallback((id, label, title, iconUrl, color = {}) => {
    return {
      id,
      label,
      title,
      shape: "image",
      image: iconUrl || iconMap.seekbase,
      font: { color: "#fff", size: 14 },
      color: {
        background: color.background || "#2E073F",
        border: color.border || "#7A1CAC",
        highlight: {
          background: "#4A1066",
          border: "#9B2AD9"
        },
        hover: {
          background: "#3A0852",
          border: "#8A1CBD"
        }
      },
      size: 30,
      scaling: {
        min: 35,
        max: 45,
        label: {
          enabled: true,
          min: 14,
          max: 16
        }
      },
      shadow: {
        enabled: true,
        color: 'rgba(0,0,0,0.5)',
        size: 10,
        x: 5,
        y: 5
      }
    };
  }, []);

  const shouldAddNode = useCallback((parentNode, label, data) => {
    if (parentNode && parentNode.label === "FiveM ID" && label === "Email") {
      return false;
    }

    const totalChildrenCount = countKeys(data);
    if (totalChildrenCount > MAX_CHILDREN_PER_NODE) {
      return false;
    }

    return true;
  }, [countKeys]);

  const updateNodeLookup = useCallback((id, lookupData) => {
    setNodeLookup(prev => ({
      ...prev,
      [id]: { lookupData }
    }));
  }, []);

  const fetchAndProcess = useCallback(async (query, parentId, depth) => {
    if (!query || currentIdRef.current > MAX_NODES || depth > MAX_DEPTH) return;

    try {
      const queryParams = new URLSearchParams({
        search_string: query,
        filename: true,
      });

      const { signature, timestamp } = generateHMAC(query);

      const response = await fetch(`https://api.seekbase.shop/api/v1/search?${queryParams.toString()}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
          "X-Signature": signature,
          "X-Timestamp": timestamp,
        }
      });

      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

      const data = await response.json();
      if (!data || Object.values(data).every(value => 
        !value || value === '' || (typeof value === 'object' && Object.keys(value).length === 0)
      )) {
        toast.info("No results found");
        return;
      }

      const newNodes = [];
      const newEdges = [];

      const processInfo = (info, label, icon, lookupData) => {
        if (!info || !label) return;
        
        if (!infosPoolRef.current.has(info) && currentIdRef.current <= MAX_NODES && shouldAddNode(null, label, data)) {
          infosPoolRef.current.add(info);
          const node = createNode(currentIdRef.current, label, `${label}: ${info}`, icon);
          
          if (node) {
            newNodes.push(node);
            newEdges.push({ from: parentId, to: currentIdRef.current });
            
            updateNodeLookup(currentIdRef.current, lookupData);
            
            const nextId = currentIdRef.current;
            currentIdRef.current++;
            
            fetchAndProcess(info, nextId, depth + 1);
          }
        }
      };

      const processEntries = (entries, type, icon) => {
        if (entries && typeof entries === 'object') {
          Object.entries(entries).forEach(([key, value]) => {
            processInfo(key, type, icon, value?.lookup || null);
          });
        }
      };

      processEntries(data.emails, "Email", iconMap.email);
      processEntries(data.ips, "IP", iconMap.ip);
      processEntries(data.fivem_ids, "FiveM ID", iconMap.fivem);
      processEntries(data.discord_ids, "Discord ID", iconMap.discord);
      processEntries(data.steam_ids, "Steam ID", iconMap.steam);
      processEntries(data.fivem_licenses, "Licence Fivem", iconMap.fivem);
      processEntries(data.xbl, "Xbox ID", iconMap.xbox);
      processEntries(data.live, "Live ID", iconMap.live);
      processEntries(data.wakanime, "Wakanime", iconMap.wakanime);

      if (newNodes.length > 0) {
        setNodes(prev => [...prev, ...newNodes]);
        setEdges(prev => [...prev, ...newEdges]);
      }

    } catch (error) {
      console.error("Error in fetchAndProcess:", error);
      if (error.response?.status === 500) {
        toast.error("Server error while processing data");
      } else {
        toast.error("Error processing search results");
      }
    }
  }, [token, generateHMAC, shouldAddNode, createNode, updateNodeLookup, setNodes, setEdges]);

  const clearGraph = useCallback(() => {
    setNodes([]);
    setEdges([]);
    setNodeLookup({});
    infosPoolRef.current.clear();
    currentIdRef.current = 1;
  }, [setNodes, setEdges]);

  const handleSearch = useCallback(async () => {
    if (!validateSearchQuery(searchQuery)) {
      toast.error("Please enter a valid search query");
      return;
    }

    if (!user?.user_data?.credits || user.user_data.credits < CREDITS_COST) {
      toast.error(`You need at least ${CREDITS_COST} credits to perform a search`);
      return;
    }

    setIsLoading(true);
    setIsProcessing(true);

    try {
      clearGraph();

      const rootNode = createNode(0, searchQuery, "By SeekBase", iconMap.seekbase);
      if (!rootNode) throw new Error("Failed to create root node");

      setNodes([rootNode]);
      setNodeLookup({ 0: { lookupData: searchQuery } });

      await fetchAndProcess(searchQuery, 0, 0);

      setTimeout(async () => {
        if (currentIdRef.current > 1) {
          const deductionSuccess = await deductCredits();
          if (deductionSuccess) {
            toast.success(`Search completed successfully! ${CREDITS_COST} credits deducted`);
          } else {
            clearGraph();
          }
        }
      }, 100);

    } catch (error) {
      console.error("Search error:", error);
      if (error.response) {
        switch (error.response.status) {
          case 401:
            toast.error("Session expired. Please log in again");
            navigate('/login');
            break;
          case 403:
            toast.error("You don't have permission to perform this search");
            break;
          case 429:
            toast.error("Too many requests. Please wait a moment and try again");
            break;
          case 500:
            toast.error("Server error. Please try again later");
            break;
          default:
            toast.error(error.response.data?.message || "An error occurred during search");
        }
      } else if (error.request) {
        toast.error("Network error. Please check your connection");
      } else {
        toast.error("An unexpected error occurred");
      }
      clearGraph();
    } finally {
      setIsLoading(false);
      setIsProcessing(false);
    }
  }, [searchQuery, user, clearGraph, createNode, deductCredits, fetchAndProcess, navigate, validateSearchQuery]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const session = await supabase.auth.getSession();
        if (!session?.data?.session?.access_token) {
          toast.error("Authentication failed. Please log in again.");
          navigate('/login');
          return;
        }

        const token = session.data.session.access_token;
        const response = await axios.get('https://api.seekbase.shop/api/v1/user/me', {
          headers: { Authorization: `Bearer ${token}` }
        });

        if (!response?.data) {
          throw new Error("Failed to fetch user data");
        }

        setToken(token);
        setUser(response.data);
      } catch (error) {
        console.error("Error fetching user:", error);
        toast.error(error.response?.data?.message || "Failed to fetch user data");
        if (error.response?.status === 401) {
          navigate('/login');
        }
      }
    };

    fetchUser();
  }, [navigate]);

  return {
    searchQuery,
    setSearchQuery,
    isLoading,
    isProcessing,
    user,
    token,
    nodeLookup,
    handleSearch,
    clearGraph,
    updateNodeLookup
  };
};

export default useGraphSearch;