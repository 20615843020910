import fivem_logo from "../images/fivem_logo.png";
import discord_logo from "../images/discord_logo.png";
import steam_logo from "../images/steam_logo.png";
import email_logo from "../images/email_logo.png";
import ip_logo from "../images/ip_logo.png";
import xbox_logo from "../images/xbox_logo.png";
import live_logo from "../images/xbox_logo.png";
import seekbase_logo from "../images/seekbase_logo.png";
import wakanime_logo from "../images/wakanime_logo.png";

export const iconMap = {
  email: email_logo,
  ip: ip_logo,
  xbox: xbox_logo,
  live: live_logo,
  fivem: fivem_logo,
  steam: steam_logo,
  discord: discord_logo,
  seekbase: seekbase_logo,
  wakanime: wakanime_logo,
};