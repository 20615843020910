import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';  
import { toast } from 'react-toastify';
import supabase from '../supabase';
import { CiLogout,CiMenuBurger } from "react-icons/ci";
import './styles/HeaderDashboard.css';
import seeklogo from '../assets/logo.png';


function HeaderDashboard() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  
  useEffect(() => {
    const fetchUser = async () => {
      try {
        let token = ((await supabase.auth.getSession()).data.session.access_token);
        if (token) {
          const response = await axios.get('https://api.seekbase.shop/api/v1/user/me', {
            headers: { Authorization: `Bearer ${token}` },
          });
          setUser(response.data);
        }
      } catch (error) {
          console.error("Error fetching user:", error);
      }
    };

    fetchUser();
  }, [navigate]);

  const handleLogout = () => {
    const { error } = supabase.auth.signOut();
    if (error) {
      toast.error("Failed to logout: " + error.message);
    }
    localStorage.removeItem('access_token'); 
    setUser(null);
    navigate('/');
  };

  return (
    <div className="header-dashboard-container">
      <header className="header-dashboard">
        <div className="header-dashboard-logo">
          <Link to="/">
            <img src={seeklogo} alt='Seekbase logo'></img>
          </Link>
        </div>
        
        <div className="header-dashboard-menu-icon" onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <CiMenuBurger size={24} />
        </div>

        <nav className={`header-dashboard-nav ${isMenuOpen ? 'open' : ''}`}>
          {!user ? (
            <div className="header-dashboard-auth-buttons">
              <Link to="/login" className="header-dashboard-auth-button">Login</Link>
              <Link to="/signup" className="header-dashboard-auth-button signup">Signup</Link>
            </div>
          ) : (
            <div className="header-dashboard-user-info">
              <img 
                src={user?.user_data?.avatar_url}
                alt="User Avatar" 
                className="header-dashboard-user-avatar"
              />
              <span className='username'>{user?.user_data?.username || 'User'}</span>
              <button className="header-dashboard-logout-button" onClick={handleLogout}>
                <CiLogout size={18} />
                <span>Logout</span>
              </button>
            </div>
          )}
        </nav>
      </header>
    </div>
  );
}

export default HeaderDashboard