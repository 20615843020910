import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet, NavLink } from 'react-router-dom';
import { LayoutDashboard, History, UserCircle, DollarSign, Menu, X } from 'lucide-react';
import '../../styles/Dashboard/Dashboard.css'
import HeaderDashboard from '../../components/HeaderDashboard';

function Dashboard() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const menuItems = [
    { icon: <LayoutDashboard size={24} />, text: 'Graph', path: '/dashboard/graph' },
    { icon: <UserCircle size={24} />, text: 'Profile', path: '/dashboard/profile' },
    { icon: <DollarSign size={24} />, text: 'Price', path: '/dashboard/price' },
  ];

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    setIsExpanded(!isMobileMenuOpen);
  };

  const handleMouseEnter = () => {
    if (window.innerWidth > 768) {
      setIsExpanded(true);
    }
  };

  const handleMouseLeave = () => {
    if (window.innerWidth > 768) {
      setIsExpanded(false);
    }
  };

  return (
    <div className="dashboard-container">
      <Helmet>
        <title>Dashboard | Seekbase</title>
        <link rel="icon" type="image/png" href="favicon.png" sizes="16x16" />
      </Helmet>
      <HeaderDashboard></HeaderDashboard>
      <button 
        className="mobile-menu-toggle"
        onClick={toggleMobileMenu}
        aria-label="Toggle menu"
      >
        {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
      </button>

      <aside 
        className={`dashboard-sidebar ${isExpanded ? 'expanded' : ''} ${isMobileMenuOpen ? 'mobile-open' : ''}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <nav className="sidebar-content">
          {menuItems.map((item, index) => (
            <NavLink
              key={index}
              to={item.path}
              className={({ isActive }) => 
                `sidebar-item ${isActive ? 'active' : ''}`
              }
              onClick={() => window.innerWidth <= 768 && toggleMobileMenu()}
            >
              <span className="icon">{item.icon}</span>
              <span className="text">{item.text}</span>
            </NavLink>
          ))}
        </nav>
      </aside>

      <main className={`dashboard-main ${isExpanded ? 'sidebar-expanded' : ''}`}>
        <div className="content-wrapper">
          <Outlet />
        </div>
      </main>
    </div>
  );
}

export default Dashboard;