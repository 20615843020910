const STORAGE_KEY = 'seekbase_saved_graphs';

export const saveGraphToStorage = (graphs) => {
  try {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(graphs));
    return true;
  } catch (error) {
    console.error('Error saving graphs:', error);
    return false;
  }
};

export const loadGraphsFromStorage = () => {
  try {
    const saved = localStorage.getItem(STORAGE_KEY);
    return saved ? JSON.parse(saved) : [];
  } catch (error) {
    console.error('Error loading graphs:', error);
    return [];
  }
};

export const validateGraphData = (data) => {
  console.log(data)
  return (
    data &&
    Array.isArray(data.data.nodes) &&
    Array.isArray(data.data.edges) &&
    typeof data.data.lookupData === 'object'
  );
};