import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Mail, Lock, Loader } from 'lucide-react';
import supabase from '../supabase';
import '../styles/Auth.css';
import { Helmet } from 'react-helmet-async';
import DOMPurify from 'dompurify';
import axios from 'axios';
import Footer from '../components/Footer';
import seeklogo from '../assets/logo.png';

function Login() {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    setError(''); // Clear error when user starts typing
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      const cleanEmail = DOMPurify.sanitize(formData.email);
      const cleanPassword = DOMPurify.sanitize(formData.password);

      const { error: authError, data } = await supabase.auth.signInWithPassword({
        email: cleanEmail,
        password: cleanPassword,
      });

      if (authError) throw authError;

      const token = data.session.access_token;
      const response = await axios.get('https://api.seekbase.shop/api/v1/user/me', {
        headers: { Authorization: `Bearer ${token}` },
      });

      toast.success("Welcome back!");
      navigate("/dashboard");

    } catch (error) {
      console.error("Login error:", error);
      setError(error.message || 'Failed to login. Please try again.');
      toast.error(error.message || 'Login failed');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="auth__container">
      <Helmet>
        <title>Login | Seekbase</title>
        <meta name="description" content="Login to your Seekbase account" />
        <link rel="icon" type="image/png" href="favicon.png" sizes="16x16" />
      </Helmet>

      <div className="auth__logo-wrapper">
        <Link to="/">
          <img src={seeklogo} alt="SeekBase Logo" className="auth__logo" />
        </Link>
      </div>

      <div className="auth__form-container">
        <div className="auth__form-wrapper">
          <h1 className="auth__title">Welcome Back</h1>
          
          {error && <div className="auth__error">{error}</div>}

          <form onSubmit={handleLogin} className="auth__form">
            <div className="auth__input-group">
              <input
                type="email"
                id="email"
                name="email"
                className="auth__input"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email address"
                required
                disabled={isLoading}
              />
              <Mail className="auth__input-icon" size={18} />
            </div>

            <div className="auth__input-group">
              <input
                type="password"
                id="password"
                name="password"
                className="auth__input"
                value={formData.password}
                onChange={handleChange}
                placeholder="Password"
                required
                disabled={isLoading}
              />
              <Lock className="auth__input-icon" size={18} />
            </div>

            <button 
              type="submit" 
              className={`auth__submit-btn ${isLoading ? 'auth__submit-btn--loading' : ''}`}
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <Loader className="animate-spin" size={18} />
                  <span>Logging in...</span>
                </>
              ) : (
                'Login'
              )}
            </button>
          </form>

          <div className="auth__links">
            <Link to="/forgot-password" className="auth__link">
              Forgot password?
            </Link>
            <p className="mt-2">
              Don't have an account?{' '}
              <Link to="/signup" className="auth__link">
                Sign up
              </Link>
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Login;