import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Mail, Lock, User, Loader } from 'lucide-react';
import { FaGoogle } from 'react-icons/fa';
import '../styles/Auth.css';
import { Helmet } from 'react-helmet-async';
import DOMPurify from 'dompurify';
import seeklogo from '../assets/logo.png';
import Footer from '../components/Footer';
function Signup() {
  const [formData, setFormData] = useState({
    email: '',
    username: '',
    password: '',
    passwordConfirm: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    setError('');
  };

  const validateForm = () => {
    if (formData.password.length < 8) {
      setError('Password must be at least 8 characters long');
      return false;
    }
    if (formData.password !== formData.passwordConfirm) {
      setError('Passwords do not match');
      return false;
    }
    return true;
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    
    setIsLoading(true);
    setError('');

    try {
      const cleanData = {
        email: DOMPurify.sanitize(formData.email),
        username: DOMPurify.sanitize(formData.username),
        password: DOMPurify.sanitize(formData.password)
      };

      const response = await fetch('https://api.seekbase.shop/api/v1/auth/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(cleanData),
      });

      const data = await response.json();
      
      if (response.ok) {
        toast.success('Account created successfully!');
        navigate('/login');
      } else {
        throw new Error(data.detail || 'Failed to create account');
      }
    } catch (error) {
      console.error('Signup error:', error);
      setError(error.message);
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOAuthSignup = async () => {
    try {
      const response = await fetch('https://api.seekbase.shop/api/v1/auth/google-auth', {
        method: "POST",
      });

      if (response.ok) {
        toast.success('Google signup successful!');
        navigate('/');
      } else {
        const data = await response.json();
        throw new Error(data.detail);
      }
    } catch (error) {
      console.error('OAuth error:', error);
      toast.error(error.message);
    }
  };

  return (
    <div className="auth__container">
      <Helmet>
        <title>Sign Up | Seekbase</title>
        <meta name="description" content="Create your Seekbase account" />
        <link rel="icon" type="image/png" href="favicon.png" sizes="16x16" />
      </Helmet>

      <div className="auth__logo-wrapper">
        <Link to="/">
          <img src={seeklogo} alt="SeekBase Logo" className="auth__logo" />
        </Link>
      </div>

      <div className="auth__form-container">
        <div className="auth__form-wrapper">
          <h1 className="auth__title">Create Account</h1>
          
          {error && <div className="auth__error">{error}</div>}

          <form onSubmit={handleSignup} className="auth__form">
            <div className="auth__input-group">
              <input
                type="email"
                id="email"
                name="email"
                className="auth__input"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email address"
                required
                disabled={isLoading}
              />
              <Mail className="auth__input-icon" size={18} />
            </div>

            <div className="auth__input-group">
              <input
                type="text"
                id="username"
                name="username"
                className="auth__input"
                value={formData.username}
                onChange={handleChange}
                placeholder="Username"
                required
                disabled={isLoading}
              />
              <User className="auth__input-icon" size={18} />
            </div>

            <div className="auth__input-group">
              <input
                type="password"
                id="password"
                name="password"
                className="auth__input"
                value={formData.password}
                onChange={handleChange}
                placeholder="Password"
                required
                disabled={isLoading}
              />
              <Lock className="auth__input-icon" size={18} />
            </div>

            <div className="auth__input-group">
              <input
                type="password"
                id="passwordConfirm"
                name="passwordConfirm"
                className="auth__input"
                value={formData.passwordConfirm}
                onChange={handleChange}
                placeholder="Confirm password"
                required
                disabled={isLoading}
              />
              <Lock className="auth__input-icon" size={18} />
            </div>

            <button 
              type="submit" 
              className={`auth__submit-btn ${isLoading ? 'auth__submit-btn--loading' : ''}`}
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <Loader className="animate-spin" size={18} />
                  <span>Creating account...</span>
                </>
              ) : (
                'Create Account'
              )}
            </button>
          </form>

          <div className="auth__oauth-divider">or</div>

          <button 
            onClick={handleOAuthSignup}
            className="auth__oauth-btn"
            type="button"
            disabled={isLoading}
          >
            <FaGoogle size={18} />
            Continue with Google
          </button>

          <div className="auth__links">
            <p>
              Already have an account?{' '}
              <Link to="/login" className="auth__link">
                Login
              </Link>
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Signup;