import React from 'react';
import { Helmet } from 'react-helmet-async';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { BsLightningChargeFill } from "react-icons/bs";
import { IoCodeSlashOutline } from "react-icons/io5";
import { PiGraphThin } from "react-icons/pi";
import { CiSearch, CiDatabase, CiFolderOn } from 'react-icons/ci';
import { Link } from 'react-router-dom';
import '../styles/Home.css';

const FeatureRow = ({ icon: Icon, title, description, index, isReversed }) => {
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true
  });

  return (
    <motion.div
      ref={ref}
      className={`feature-row ${isReversed ? 'reversed' : ''}`}
      initial={{ opacity: 0, x: isReversed ? 100 : -100 }}
      animate={inView ? { opacity: 1, x: 0 } : {}}
      transition={{ duration: 0.8, delay: index * 0.2 }}
    >
      <div className="feature-content">
        <h3 className="feature-title">{title}</h3>
        <p className="feature-description">{description}</p>
      </div>
      <div className="feature-icon-wrapper">
        <Icon size={40} />
      </div>
    </motion.div>
  );
};

const Home = () => {
  const data = { "api_files_count": "36953", "folder_api_size": "419GB" };
  
  const features = [
    {
      icon: BsLightningChargeFill,
      title: "Lightning Fast API",
      description: "Experience blazing-fast response times with our optimized API infrastructure."
    },
    {
      icon: PiGraphThin,
      title: "Advanced Visualization",
      description: "Powerful graph visualization tools with automatic relationship discovery."
    },
    {
      icon: IoCodeSlashOutline,
      title: "Developer Friendly",
      description: "Comprehensive documentation and SDKs for seamless integration."
    },
    {
      icon: CiDatabase,
      title: "Scalable Storage",
      description: "Databases are constantly being added to Seekbase to ensure that results are always relevant."
    },
    {
      icon: CiSearch,
      title: "Search lookup",
      description: "Automatic lookup for research results, IP, discord ID, fivem ID, xbox live ID "
    }
  ];

  return (
    <div>
      <Helmet>
        <title>Home | Seekbase</title>
        <link rel="icon" type="image/png" href="favicon.png" sizes="16x16" />
      </Helmet>
      <Header />
      <main className="home">
        {/* Hero Section */}
        <motion.section 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          className="hero-section"
        >
          <motion.div
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.8 }}
            className="hero-content"
          >
            <motion.div 
              className="hero-title-wrapper"
              initial={{ scale: 0.9 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              <CiSearch className="hero-icon" />
              <h1 className="hero-title">SeekBase</h1>
            </motion.div>
            <motion.p 
              className="hero-subtitle"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.4 }}
            >
              The best searching API, simple and powerful
            </motion.p>
            <motion.div 
              className="hero-cta"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <Link to="/login" className="cta-button">
                START NOW
                <motion.span
                  className="button-arrow"
                  animate={{ x: [0, 5, 0] }}
                  transition={{ duration: 1.5, repeat: Infinity }}
                >
                  →
                </motion.span>
              </Link>
            </motion.div>
          </motion.div>

          <div className="particles-container">
            {[...Array(20)].map((_, i) => (
              <motion.div
                key={i}
                className="particle"
                initial={{
                  x: Math.random() * 100 + '%',
                  y: Math.random() * 100 + '%',
                }}
                animate={{
                  y: ['-20%', '120%'],
                }}
                transition={{
                  duration: Math.random() * 10 + 10,
                  repeat: Infinity,
                  ease: 'linear',
                  delay: Math.random() * -10,
                }}
              />
            ))}
          </div>
        </motion.section>

        {/* Stats Section */}
        <motion.section 
          className="stats-section"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
        >
          <div className="stats-container">
            <motion.div 
              className="stat-item"
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <h3>Files in API</h3>
              <motion.div 
                className="stat-number"
                initial={{ scale: 0 }}
                whileInView={{ scale: 1 }}
                viewport={{ once: true }}
              >
                {data.api_files_count}
              </motion.div>
              <CiFolderOn className="stat-icon" size={70} />
            </motion.div>
            <motion.div 
              className="stat-item"
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <h3>Data Stored (GB)</h3>
              <motion.div 
                className="stat-number"
                initial={{ scale: 0 }}
                whileInView={{ scale: 1 }}
                viewport={{ once: true }}
              >
                {data.folder_api_size}
              </motion.div>
              <CiDatabase className="stat-icon" size={70} />
            </motion.div>
          </div>
        </motion.section>

        {/* Features Section */}
        <section className="features-section">
          <motion.div
            className="features-header"
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
          </motion.div>

          <div className="features-container">
            {features.map((feature, index) => (
              <FeatureRow 
                key={index} 
                {...feature} 
                index={index} 
                isReversed={index % 2 !== 0}
              />
            ))}
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
};

export default Home;