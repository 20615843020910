import { Star, Check } from 'lucide-react';

const PricingCard = ({ title, price, features, popular, sellixId }) => {
  return (
    <div className={`pricing-card ${popular ? 'featured' : ''}`}>
      {popular && <div className="popular-tag">Most Popular</div>}
      <div className="card-content">
        <div className="card-header">
          {popular ? <Star className="icon star-icon" /> : <div className="icon-circle" />}
          <h3>{title}</h3>
        </div>
        
        <div className="price-container">
          <span className="currency">$</span>
          <span className="amount">{price}</span>
          <span className="period">/month</span>
        </div>

        <ul className="features-list">
          {features.map((feature, index) => (
            <li key={index}>
              <Check className="check-icon" />
              <span>{feature}</span>
            </li>
          ))}
        </ul>

        <button 
          className="purchase-button" 
          data-sellix-product={sellixId}
        >
          <span className="button-text">Get Started</span>
          <div className="button-glow" />
        </button>
      </div>
    </div>
  );
};

export default PricingCard;