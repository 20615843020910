import React from 'react';
import { BookMarked, ChevronUp, Upload, Trash2 } from 'lucide-react';

const SavedGraphsList = ({ 
  isOpen, 
  savedGraphs, 
  onToggle, 
  onLoad, 
  onDelete 
}) => {
  return (
    <div className={`saved-graphs-panel ${isOpen ? 'open' : ''}`}>
      <button className="saved-graphs-toggle" onClick={onToggle}>
        <BookMarked size={18} />
        <span>Saved Graphs</span>
        <ChevronUp className={`toggle-icon ${isOpen ? 'open' : ''}`} size={18} />
      </button>
      
      {isOpen && (
        <div className="saved-graphs-list">
          {savedGraphs.length === 0 ? (
            <div className="empty-state">
              No saved graphs yet
            </div>
          ) : (
            savedGraphs.map((graph, index) => (
              <div key={index} className="saved-graph-item">
                <span className="saved-graph-title">{graph.title}</span>
                <div className="saved-graph-actions">
                  <button 
                    onClick={() => onLoad(graph)}
                    className="load-graph-button"
                  >
                    <Upload size={16} />
                    <span>Load</span>
                  </button>
                  <button 
                    onClick={() => onDelete(index)}
                    className="delete-graph-button"
                  >
                    <Trash2 size={16} />
                    <span>Delete</span>
                  </button>
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default SavedGraphsList;