import React, { useEffect, useRef } from 'react';
import { Network } from "vis-network/standalone";
import "vis-network/styles/vis-network.css";

const GraphNetwork = ({ nodes, edges, isLowPerformanceMode, onNodeSelect }) => {
  const containerRef = useRef(null);
  const networkRef = useRef(null);

  useEffect(() => {
    if (!containerRef.current) return;

    const options = {
      nodes: {
        shape: "box",
        color: {
          background: "#2E073F",
          border: "#7A1CAC",
          highlight: { background: "#4A1066", border: "#9B2AD9" },
          hover: { background: "#3A0852", border: "#8A1CBD" }
        },
        font: { color: "#EBD3F8" },
        labelHighlightBold: true,
        borderWidth: 2,
        shadow: true
      },
      edges: {
        color: {
          color: "#7A1CAC",
          highlight: "#9B2AD9",
          hover: "#8A1CBD"
        },
        width: 2,
        selectionWidth: 3,
        hoverWidth: 2.5,
        shadow: true
      },
      physics: {
        enabled: !isLowPerformanceMode,
        stabilization: {
          enabled: true,
          iterations: isLowPerformanceMode ? 100 : 1000,
          updateInterval: isLowPerformanceMode ? 50 : 100
        },
        barnesHut: {
          gravitationalConstant: -2000,
          centralGravity: 0.3,
          springLength: 200,
          springConstant: 0.04,
        },
      },
      interaction: {
        hover: true,
        tooltipDelay: 300,
        keyboard: {
          enabled: true,
          speed: { x: 10, y: 10, zoom: 0.1 },
          bindToWindow: true
        },
        zoomView: true
      }
    };

    const network = new Network(containerRef.current, { nodes, edges }, options);
    networkRef.current = network;

    network.on("click", (params) => {
      const nodeId = params.nodes[0];
      if (nodeId) {
        const nodeData = nodes.find((node) => node.id === nodeId);
        onNodeSelect(nodeData);
        network.focus(nodeId, {
          scale: 1.2,
          animation: {
            duration: 500,
            easingFunction: "easeInOutQuad"
          }
        });
      } else {
        onNodeSelect(null);
      }
    });

    return () => {
      if (networkRef.current) {
        networkRef.current.destroy();
      }
    };
  }, [nodes, edges, isLowPerformanceMode, onNodeSelect]);

  return (
    <div 
      ref={containerRef}
      className="graph-container"
      role="application"
      aria-label="Network Graph"
    />
  );
};

export default GraphNetwork;