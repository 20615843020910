import React from 'react';
import DiscordLookup from '../lookupComponents/DiscordLookup';
import SteamLookup from '../lookupComponents/SteamLookup';
import IpLookup from '../lookupComponents/IpLookup';
import FivemidLookup from '../lookupComponents/FivemidLookup';
import XboxLookup from '../lookupComponents/XboxLookup';

const NodeDetails = ({ node, nodeLookup, onClose }) => {
  if (!node) return null;

  return (
    <aside className="side-panel">
      <div className="details-node">
        <header className="details-header">
          <h3 className="details-title">Node Details</h3>
          <button 
            className="close-details"
            onClick={onClose}
            aria-label="Close details"
          >
            ×
          </button>
        </header>
        <div className="details-content">
          <section className="info-section">
            <h4>Information</h4>
            <pre className="info-content">{node.title}</pre>
          </section>

          {node.id !== undefined && (
            <section className="lookup-section">
              <h4>Additional Information</h4>
              <div className="lookup-content">
                {node.label === 'IP' && (
                  <IpLookup lookupData={nodeLookup[node.id]?.lookupData} />
                )}
                {node.label === 'FiveM ID' && (
                  <FivemidLookup lookupData={nodeLookup[node.id]?.lookupData} />
                )}
                {node.label === 'Discord ID' && (
                  <DiscordLookup lookupData={nodeLookup[node.id]?.lookupData} />
                )}
                {node.label === 'Steam ID' && (
                  <SteamLookup steamId={node.title.replace("Steam ID: ", "")} />
                )}
                {node.label === 'Xbox ID' && (
                  <XboxLookup xuid={node.title.replace("Xbox ID: ", "")} />
                )}
              </div>
            </section>
          )}
        </div>
      </div>
    </aside>
  );
};

export default NodeDetails;