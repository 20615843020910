import React from 'react';
import { FaCoins } from "react-icons/fa";
import { Download, Save, Upload } from "lucide-react";
import { toast } from 'react-toastify';

const GraphControls = ({
  credits,
  max_credits,
  isLowPerformanceMode,
  setIsLowPerformanceMode,
  onExport,
  onImport,
  graphTitle,
  setGraphTitle,
  onSave,
  fileInputRef,
  hasNodes,
  rootNode
}) => {
  const handleExport = async (format) => {
    if (!hasNodes) {
      toast.warning('No graph data to export');
      return;
    }

    try {
      await onExport(format);
    } catch (error) {
      console.error('Export error:', error);
    }
  };

  React.useEffect(() => {
    if (rootNode && !graphTitle) {
      setGraphTitle(rootNode.label);
    }
  }, [rootNode, graphTitle, setGraphTitle]);

  return (
    <div className="graph-controls-wrapper">
      <div className="graph-controls">
        <div className="controls-container">
          <div className="credits-badge" title="Available Credits">
            <FaCoins className="credits-icon" aria-hidden="true" />
            <span className="credits-amount">{credits || 0}/{max_credits || 0}</span>
          </div>
          <div className="performance-toggle">
            <label className="switch" title="Toggle Performance Mode">
              <input
                type="checkbox"
                checked={isLowPerformanceMode}
                onChange={(e) => setIsLowPerformanceMode(e.target.checked)}
                aria-label="Low Performance Mode"
              />
              <span className="toggle-label">Low Performance Mode</span>
            </label>
          </div>
        </div>
      </div>

      <div className="graph-actions">
        
        <div className="export-controls">
          <button 
            onClick={() => handleExport('json')} 
            className="action-button"
            disabled={!hasNodes}
            title={!hasNodes ? 'No graph data to export' : 'Export as JSON'}
          >
            <Download size={18} /> Export
          </button>
        </div>
        <div className="import-controls">
          <input
            type="file"
            ref={fileInputRef}
            onChange={onImport}
            accept=".json"
            style={{ display: 'none' }}
          />
          <button 
            onClick={() => fileInputRef.current?.click()} 
            className="action-button"
            title="Import graph from JSON"
          >
            <Upload size={18} /> Import
          </button>
        </div>
      </div>

      <div className="save-graph-controls">
        <input
          type="text"
          value={graphTitle}
          onChange={(e) => setGraphTitle(e.target.value)}
          placeholder="Enter graph title"
          className="graph-title-input"
          title="Enter a title for your graph"
        />
        <button 
          onClick={onSave}
          disabled={!graphTitle.trim() || !hasNodes}
          className="action-button"
          title={!hasNodes ? 'No graph data to save' : 'Save graph'}
        >
          <Save size={18} /> Save Graph
        </button>
      </div>
    </div>
  );
};

export default GraphControls;

