import React, { useEffect } from 'react';
import './styles/IpLookup.css'; // Assurez-vous que ce chemin est correct
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

const IpLookup = ({ lookupData }) => {
  const isLookupDataAvailable = lookupData && typeof lookupData === 'object';

  useEffect(() => {
    if (!isLookupDataAvailable) return;

    const { latitude, longitude } = lookupData;

    // Vérification des coordonnées valides
    if (typeof latitude !== 'number' || typeof longitude !== 'number') {
      console.error("Invalid coordinates:", latitude, longitude);
      return;
    }

    let map;
    try {
      map = L.map('map').setView([latitude, longitude], 13);

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19,
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(map);

      L.marker([latitude, longitude])
        .addTo(map)
        .bindPopup(`Position: ${latitude}, ${longitude}`)
        .openPopup();
    } catch (error) {
      console.error("Error initializing map:", error);
    }

    return () => {
      if (map) {
        map.remove();
      }
    };
  }, [isLookupDataAvailable, lookupData]);

  if (!isLookupDataAvailable) {
    return (
      <div className="ip-container">
        <div className="ip-card">
          <div className="ip-no-data">
            <i className="fas fa-search"></i>
            <p>No data available</p>
          </div>
        </div>
      </div>
    );
  }

  // Extraction des données avec des valeurs par défaut
  const {
    query = 'Unknown',
    country = 'Unknown',
    region = 'Unknown',
    city = 'Unknown',
    zip = 'Unknown',
    latitude = 'Unknown',
    longitude = 'Unknown',
    timezone = 'Unknown',
    isp = 'Unknown',
    org = 'Unknown',
    mobile = false,
    satellite = false,
    proxy = false,
    hosting = false,
  } = lookupData;

  return (
    <div className="ip-container">
      <div className="ip-card">
        <div className="ip-header">
          <div className="ip-title-wrapper">
            <i className="fas fa-network-wired"></i>
            <h2>IP Information</h2>
          </div>
          <span className="ip-address">{query}</span>
        </div>

        <div className="ip-content">
          <div className="ip-info-grid">
            <div className="ip-info-item">
              <i className="fas fa-globe"></i>
              <div className="ip-info-text">
                <span className="ip-label">Country</span>
                <span className="ip-value">{country}</span>
              </div>
            </div>

            <div className="ip-info-item">
              <i className="fas fa-map-marker-alt"></i>
              <div className="ip-info-text">
                <span className="ip-label">Region</span>
                <span className="ip-value">{region}</span>
              </div>
            </div>

            <div className="ip-info-item">
              <i className="fas fa-city"></i>
              <div className="ip-info-text">
                <span className="ip-label">City</span>
                <span className="ip-value">{city}</span>
              </div>
            </div>

            <div className="ip-info-item">
              <i className="fas fa-mail-bulk"></i>
              <div className="ip-info-text">
                <span className="ip-label">Postal Code</span>
                <span className="ip-value">{zip}</span>
              </div>
            </div>

            <div className="ip-info-item">
              <i className="fas fa-location-arrow"></i>
              <div className="ip-info-text">
                <span className="ip-label">Coordinates</span>
                <span className="ip-value">{latitude}, {longitude}</span>
              </div>
            </div>

            <div className="ip-info-item">
              <i className="fas fa-clock"></i>
              <div className="ip-info-text">
                <span className="ip-label">Timezone</span>
                <span className="ip-value">{timezone}</span>
              </div>
            </div>

            <div className="ip-info-item">
              <i className="fas fa-server"></i>
              <div className="ip-info-text">
                <span className="ip-label">ISP</span>
                <span className="ip-value">{isp}</span>
              </div>
            </div>

            <div className="ip-info-item">
              <i className="fas fa-building"></i>
              <div className="ip-info-text">
                <span className="ip-label">Organization</span>
                <span className="ip-value">{org}</span>
              </div>
            </div>
          </div>

          <div className="ip-additional-info">
            <div className="ip-status-grid">
              <div className="ip-status-item">
                <i className="fas fa-mobile-alt"></i>
                <span className="ip-status-label">Mobile</span>
                <span className={`ip-status-badge ${mobile ? 'active' : 'inactive'}`}>
                  {mobile ? "Yes" : "No"}
                </span>
              </div>

              <div className="ip-status-item">
                <i className="fas fa-satellite-dish"></i>
                <span className="ip-status-label">Satellite</span>
                <span className={`ip-status-badge ${satellite ? 'active' : 'inactive'}`}>
                  {satellite ? "Yes" : "No"}
                </span>
              </div>

              <div className="ip-status-item">
                <i className="fas fa-shield-alt"></i>
                <span className="ip-status-label">Proxy</span>
                <span className={`ip-status-badge ${proxy ? 'active' : 'inactive'}`}>
                  {proxy ? "Yes" : "No"}
                </span>
              </div>

              <div className="ip-status-item">
                <i className="fas fa-server"></i>
                <span className="ip-status-label">Hosting</span>
                <span className={`ip-status-badge ${hosting ? 'active' : 'inactive'}`}>
                  {hosting ? "Yes" : "No"}
                </span>
              </div>
            </div>
          </div>

          <div id="map" className="ip-map"></div>
        </div>
      </div>
    </div>
  );
};

export default IpLookup;
