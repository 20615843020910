import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import supabase from '../supabase';
import '../styles/FormStyles.css';
import seeklogo from '../assets/logo.png';
import Footer from '../components/Footer';

function ResetPassword() {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleResetPassword = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      toast.error('Passwords do not match. Please try again.');
      return;
    }

    const { error } = await supabase.auth.updateUser({
      password: newPassword,
    });

    if (error) {
      toast.error('There was an error updating your password: ' + error.message);
    } else {
      toast.success('Password updated successfully!');
    }
  };

  return (
    <div>
      <Helmet>
        <title>Reset password | Seekbase</title>
        <link rel="icon" type="image/png" href="favicon.png" sizes="16x16" />        
      </Helmet>
    <div className='logo-auth'><Link to="/"><img src={seeklogo} alt="SeekBase Logo" /></Link></div>
    <form className="form-container" onSubmit={handleResetPassword}>
      <h2>Reset Your Password</h2>
      <input
        type="password"
        placeholder="Enter your new password"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
        required
      />
      <input
        type="password"
        placeholder="Confirm your new password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        required
      />
      <button type="submit">Reset Password</button>
    </form>
    </div>
  );
}

export default ResetPassword;
