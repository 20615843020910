import React, { useState } from 'react';
import { Gamepad2, Trophy, Users, Star, Clock, MapPin, Info } from 'lucide-react';
import '../../styles/Dashboard/XboxLookup.css';
import supabase from '../../supabase';
import { ReactComponent as XboxLogo } from "../../assets/xbox_logo.svg";


const XboxLookup = ({ xuid }) => {
  const [xboxData, setXboxData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchXboxData = async () => {
    setIsLoading(true);
    setError(null);
    const session = await supabase.auth.getSession();
    const token = session.data.session?.access_token;

    try {
      const response = await fetch(`https://api.seekbase.shop/api/v1/search/xbox-infos?xbox_id=${xuid}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        setXboxData(data);
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Failed to fetch Xbox data');
      }
    } catch (err) {
      setError('An error occurred while fetching Xbox data');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="xbox-lookup">
        <div className="loader-container">
          <div className="loader"></div>
          <span className="loading-text">Loading Xbox Profile...</span>
        </div>
      </div>
    );
  }

  if (!xboxData) {
    return (
      <div className="xbox-lookup">
        <button 
          onClick={fetchXboxData} 
          className="fetch-xbox-button"
          disabled={isLoading}
        >
          Fetch Xbox Profile
        </button>
        {error && <p className="xbox-error">{error}</p>}
      </div>
    );
  }

  const { profile, presence, achievements,friends } = xboxData;

  return (
    <div className="xbox-container">
      <div className="xbox-card">
        <div className="xbox-header">
          <div className="xbox-avatar-wrapper">
            <img 
              src={profile.display_picture} 
              alt={`${profile.gamertag}'s Avatar`} 
              className="xbox-avatar"
            />
            <div 
              className="xbox-status" 
              style={{ 
                backgroundColor: presence.state === 'Online' ? '#66ff8f' : '#b9bbbe'
              }}
            />
          </div>
        </div>

        <div className="xbox-content">
          <div className="xbox-user-info">
            <h1 className="xbox-gamertag">{profile.gamertag}</h1>
            <span className="xbox-status-text">
              {presence.state}
              {presence.last_seen && ` - Last seen: ${new Date(presence.last_seen).toLocaleString()}`}
            </span>
          </div>

          <div className="xbox-details">
            <div className="xbox-detail-item">
              <Trophy size={20} className="xbox-icon" />
              <span className="xbox-label">Gamerscore</span>
              <span className="xbox-value">{profile.gamerscore}</span>
            </div>

            <div className="xbox-detail-item">
              <Star size={20} className="xbox-icon" />
              <span className="xbox-label">Reputation</span>
              <span className="xbox-value">{profile.reputation}</span>
            </div>

            <div className="xbox-detail-item">
              <Users size={20} className="xbox-icon" />
              <span className="xbox-label">Account Tier</span>
              <span className="xbox-value">{profile.account_tier}</span>
            </div>

            {profile.location && (
              <div className="xbox-detail-item">
                <MapPin size={20} className="xbox-icon" />
                <span className="xbox-label">Location</span>
                <span className="xbox-value">{profile.location}</span>
              </div>
            )}

            {profile.bio && (
              <div className="xbox-detail-item">
                <Info size={20} className="xbox-icon" />
                <span className="xbox-label">Bio</span>
                <span className="xbox-value">{profile.bio}</span>
              </div>
            )}

            {presence.last_played_title && (
              <div className="xbox-detail-item">
                <Gamepad2 size={20} className="xbox-icon" />
                <span className="xbox-label">Last Played</span>
                <span className="xbox-value">{presence.last_played_title}</span>
              </div>
            )}
          </div>

          {achievements && achievements.length > 0 && (
            <div className="xbox-section">
              <h3 className="xbox-section-title">
                <Trophy size={24} />
                Games
              </h3>
              <div className="xbox-games-grid">
                {achievements.map((game, index) => (
                  <div key={index} className="xbox-game-card">
                    <div className="xbox-game-banner">
                      <img 
                        src={game.display_image} 
                        alt={game.title_name} 
                        className="xbox-game-img"
                      />
                    </div>
                    <div className="xbox-game-info">
                      <span className="xbox-game-name">{game.title_name}</span>
                      <div className="xbox-achievement-stats">
                        <span className="xbox-game-progress">
                          Progress: {game.achievement_progress.current}/{game.achievement_progress.total_score}
                        </span>
                        <span className="xbox-game-hours">
                          Last played: {new Date(game.last_time_played).toLocaleDateString()}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        
        {friends && friends.length > 0 && (
            <div className="steam-section">
              <h3 className="steam-section-title">
                <i className="fas fa-users"></i>
                Friends
              </h3>
              <div className="steam-friends-grid">
                {friends.map((friend, index) => (
                  <div key={index} className="steam-friend-card">
                    <img 
                      src={friend.display_picture}
                      alt={friend.display_name} 
                      className="steam-friend-avatar"
                    />
                    <div className="steam-friend-info">
                      <span className="steam-friend-name">{friend.display_name}</span>
                      <span className="steam-friend-status" style={{
                        color: friend.presence_state === 'Online' ? '#66ff8f' : '#ff6b6b'
                      }}>
                        {friend.presence_state}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

        <div className="xbox-footer">
          <span>Xbox Live Profile • XUID: {profile.xuid} • SeekBase </span>
        </div>
      </div>
    </div>
  );
};

export default XboxLookup;