import React from 'react';
import './styles/FivemidLookup.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

const FivemidLookup = ({ lookupData }) => {
  if (!lookupData) return null;

  const { user, badges } = lookupData;
  const {
    username,
    avatar_url,
    created_at,
    last_seen,
    profile_views,
    is_admin,
    is_moderator,
    notifications,
    title,
    trust_level,
  } = user;

  return (
    <div className="fm-container">
      <div className="fm-profile">
        <div className="fm-avatar">
          {avatar_url ? (
            <img src={avatar_url} alt={`${username}'s Avatar`} className="fm-avatar-img" />
          ) : (
            <div className="fm-avatar-placeholder">
              <i className="fas fa-user-circle"></i>
            </div>
          )}
        </div>

        <div className="fm-info">
          <h2 className="fm-title">FiveM Information</h2>
          
          <div className="fm-details">
            <div className="fm-detail-item">
              <i className="fas fa-user"></i>
              <span className="fm-label">Username:</span>
              <span className="fm-value">{username}</span>
            </div>

            <div className="fm-detail-item">
              <i className="fas fa-tag"></i>
              <span className="fm-label">Title:</span>
              <span className="fm-value">{title}</span>
            </div>

            <div className="fm-detail-item">
              <i className="fas fa-star"></i>
              <span className="fm-label">Trust Level:</span>
              <span className="fm-value">{trust_level}</span>
            </div>

            <div className="fm-detail-item">
              <i className="fas fa-calendar-alt"></i>
              <span className="fm-label">Created:</span>
              <span className="fm-value">{created_at}</span>
            </div>

            <div className="fm-detail-item">
              <i className="fas fa-clock"></i>
              <span className="fm-label">Last Seen:</span>
              <span className="fm-value">{last_seen}</span>
            </div>

            <div className="fm-detail-item">
              <i className="fas fa-eye"></i>
              <span className="fm-label">Profile Views:</span>
              <span className="fm-value">{profile_views}</span>
            </div>

            <div className="fm-detail-item">
              <i className="fas fa-user-shield"></i>
              <span className="fm-label">Admin:</span>
              <span className="fm-value">{is_admin ? 'Yes' : 'No'}</span>
            </div>

            <div className="fm-detail-item">
              <i className="fas fa-user-tag"></i>
              <span className="fm-label">Moderator:</span>
              <span className="fm-value">{is_moderator ? 'Yes' : 'No'}</span>
            </div>
          </div>

          <div className="fm-notifications">
            <h3 className="fm-subtitle">Notifications</h3>
            <div className="fm-notification-item">
              <i className="fas fa-bell"></i>
              <span className="fm-label">Notify when followed:</span>
              <span className="fm-value">{notifications.notify_when_followed ? 'Yes' : 'No'}</span>
            </div>
            <div className="fm-notification-item">
              <i className="fas fa-user-plus"></i>
              <span className="fm-label">Allow follow:</span>
              <span className="fm-value">{notifications.allow_follow ? 'Yes' : 'No'}</span>
            </div>
          </div>

          {badges.total_badges > 0 && (
            <div className="fm-badges">
              <h3 className="fm-subtitle">
                <i className="fas fa-trophy"></i>
                Badges ({badges.total_badges})
              </h3>
              <div className="fm-badges-list">
                {badges.badge_details.map((badge, index) => (
                  <div key={index} className="fm-badge-item">
                    <h4 className="fm-badge-name">{badge.name}</h4>
                    <p className="fm-badge-desc">{badge.description}</p>
                    <p className="fm-badge-meta">
                      Type: {badge.type} • Granted: {badge.granted_at}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FivemidLookup;
