import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/NotFound.css';
import seeklogo from '../assets/logo.png';


const NotFound = () => {
  return (
    <div className="not-found">
      <img src={seeklogo} alt="Logo" className="logo" />
      <h1>404 - Page Not Found</h1>
      <p>Oops! It seems like the page you're looking for doesn't exist.</p>
      <div className="buttons">
        <Link to="/" className="button">Go to Homepage</Link>
      </div>
    </div>
  );
};

export default NotFound;
