import React from 'react';
import { CiSearch } from "react-icons/ci";

const SearchBar = ({ 
  searchQuery, 
  setSearchQuery, 
  isLoading, 
  isProcessing, 
  onSearch 
}) => {
  return (
    <header className="search-section">
      <div className="search-container">
        <div className="search-bar">
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Enter search query"
            disabled={isLoading || isProcessing}
            className={`search-input ${isLoading || isProcessing ? 'loading' : ''}`}
            onKeyPress={(e) => e.key === 'Enter' && onSearch()}
            maxLength={100}
          />
          <button 
            onClick={onSearch}
            disabled={isLoading || isProcessing}
            className={`search-button ${isLoading || isProcessing ? 'loading' : ''}`}
            aria-label="Search"
          >
            {isLoading ? (
              <span className="loading-spinner"></span>
            ) : isProcessing ? (
              <span className="processing-text">Processing...</span>
            ) : (
              <><CiSearch size={24}/> Search</>
            )}
          </button>
        </div>
      </div>
    </header>
  );
};

export default SearchBar;