import PricingCard from '../../components/PricingCard';
import CreditCard from '../../components/CreditCard';
import '../../styles/Dashboard/Pricing.css'
import { Helmet } from 'react-helmet-async';

const Pricing = () => {
  const subscriptionPlans = [
    {
      title: "Seeker Basic",
      price: 4.99,
      features: [
        "Access to Graph Lookup API",
        "Integrated Info Lookup (10 SeekCoin/search)",
        "150 SeekCoin per day",
        "24/7 Customer Support"
      ],
      sellixId: "67277447d92f8"
    },
    {
      title: "Seeker Pro",
      price: 7.99,
      features: [
        "Access to Graph Lookup API",
        "Integrated Info Lookup (10 SeekCoin/search)",
        "350 SeekCoin per day",
        "24/7 Customer Support"
      ],
      popular: true,
      sellixId: "67277ed4ee603"
    },
    {
      title: "Seeker Premium",
      price: 11.99,
      features: [
        "All Basic & Pro Features",
        "Integrated Info Lookup (10 SeekCoin/search)",
        "650 SeekCoin per days",
        "Access to Discord Search Bot",
        "Access to all data not supported by graph",
        "24/7 Customer Support"
      ],
      sellixId: "674b0d78f3606"
    }
  ];

  const creditPacks = [
    {
      title: "Starter Pack",
      credits: 100,
      price: 1.00,
      sellixId: "6727a30b91295",
      description: "1€"
    },
    {
      title: "Growth Pack",
      credits: 300,
      price: 2.50,
      sellixId: "6727a310a74fc",
      description: "2.5"
    },
    {
      title: "Power Pack",
      credits: 550,
      price: 4.50,
      sellixId: "6727a31351d09",
      description: "Maximize your potential with 550 SeekCoin."
    }
  ];

  return (
    <div>
      <Helmet>
        <title>Pricing | Seekbase</title>
        <link rel="icon" type="image/png" href="favicon.png" sizes="16x16" />
      </Helmet>
      
        <div className="pricing-content">
          <section className="pricing-section">
            <h2 className="section-title">
              <span className="title-highlight">Choose</span> Your Plan
            </h2>
            <p className="section-subtitle">
              Select the perfect plan for your needs
            </p>
            
            <div className="pricing-grid">
              {subscriptionPlans.map((plan, index) => (
                <PricingCard key={index} {...plan} />
              ))}
            </div>
          </section>

          <section className="credits-section">
            <h2 className="section-title">
              <span className="title-highlight">SeekCoins</span> Packs
            </h2>
            <p className="section-subtitle">
              Power up your searches with additional credits
            </p>
            
            <div className="credits-grid">
              {creditPacks.map((pack, index) => (
                <CreditCard key={index} {...pack} />
              ))}
            </div>
          </section>
        </div>
    </div>
  );
};

export default Pricing;