import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Helmet } from "react-helmet-async";
import FileSaver from 'file-saver';
import { toast } from 'react-toastify';
import GraphNetwork from './GraphNetwork';
import GraphControls from './GraphControls';
import SearchBar from './SearchBar';
import SavedGraphsList from './SavedGraphsList';
import NodeDetails from './NodeDetails';
import { exportGraph } from '../../utils/graphExport';
import { saveGraphToStorage, loadGraphsFromStorage, validateGraphData } from '../../utils/graphStorage'
import { useGraphSearch } from '../../hooks/useGraphSearch';
import '../styles/GraphComponent.css';

const GraphComponent = () => {
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [selectedNode, setSelectedNode] = useState(null);
  const [isLowPerformanceMode, setIsLowPerformanceMode] = useState(false);
  const [graphTitle, setGraphTitle] = useState('');
  const [savedGraphs, setSavedGraphs] = useState(() => loadGraphsFromStorage());
  const [isSavedGraphsOpen, setIsSavedGraphsOpen] = useState(false);
  const [isSidePanelCollapsed, setIsSidePanelCollapsed] = useState(false);
  const fileInputRef = useRef(null);
  const networkRef = useRef(null)

  const {
    searchQuery,
    setSearchQuery,
    isLoading,
    isProcessing,
    user,
    nodeLookup,
    handleSearch,
    clearGraph,
    updateNodeLookup
  } = useGraphSearch({ setNodes, setEdges });

  useEffect(() => {
    const loadedGraphs = loadGraphsFromStorage();
    if (loadedGraphs) {
      setSavedGraphs(loadedGraphs);
    }
  }, []);

  const getMaxCredits = (subscription) => {
    switch (subscription) {
      case "free":
        return 150;
      case "basic":
        return 450;
      case "pro":
        return 1050;
      default:
        return 1950;
    }
  };

  const handleExport = async (format) => {
    try {
      const blob = await exportGraph(networkRef.current, format, nodes, edges, nodeLookup);
      if (!blob) throw new Error(`Failed to export graph as ${format}`);
      FileSaver.saveAs(blob, `graph.${format}`);
    } catch (error) {
      console.error(`Export error (${format}):`, error);
    }
  };

  const handleImport = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const importedData = JSON.parse(e.target.result);
        if (validateGraphData(importedData)) {
          clearGraph();
          setNodes(importedData.data.nodes);
          setEdges(importedData.data.edges);
          Object.entries(importedData.data.lookupData || {}).forEach(([id, data]) => {
            updateNodeLookup(parseInt(id), data.lookupData);
          });
          toast.success('Graph imported successfully!');
        } else {
          throw new Error('Invalid graph data format');
        }
      } catch (error) {
        toast.error('Error importing graph');
        console.error('Import error:', error);
      }
    };
    reader.readAsText(file);
    event.target.value = null;
  };

  const handleSave = useCallback(() => {
    if (!graphTitle.trim() || !nodes.length) return;

    const graphData = {
      title: graphTitle,
      date: new Date().toISOString(),
      data: { 
        nodes, 
        edges, 
        lookupData: nodeLookup 
      }
    };

    const updatedGraphs = [...savedGraphs, graphData];
    setSavedGraphs(updatedGraphs);
    
    if (saveGraphToStorage(updatedGraphs)) {
      setGraphTitle('');
      toast.success('Graph saved successfully!');
    } else {
      toast.error('Failed to save graph');
    }
  }, [graphTitle, nodes, edges, nodeLookup, savedGraphs]);

  const handleLoadGraph = (graph) => {
    if (!graph?.data) return;
    
    clearGraph();
    setNodes(graph.data.nodes);
    setEdges(graph.data.edges);
    
    Object.entries(graph.data.lookupData || {}).forEach(([id, data]) => {
      updateNodeLookup(parseInt(id), data.lookupData);
    });
    
    setIsSavedGraphsOpen(false);
    toast.success('Graph loaded successfully!');
  };

  const handleDeleteGraph = (index) => {
    const updated = savedGraphs.filter((_, i) => i !== index);
    setSavedGraphs(updated);
    saveGraphToStorage(updated);
    toast.success('Graph deleted successfully!');
  };

  const toggleSidePanel = () => {
    setIsSidePanelCollapsed(!isSidePanelCollapsed);
  };

  const maxCredits = getMaxCredits(user?.user_data?.subscription);

  return (
    <div className="graph-layout">
      <Helmet>
        <title>Graph | Seekbase</title>
        <link rel="icon" type="image/png" href="favicon.png" sizes="16x16" />
      </Helmet>

      <div className="main-content">
        <SearchBar
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          isLoading={isLoading}
          isProcessing={isProcessing}
          onSearch={handleSearch}
        />

        <div className="graph-content">
          <main className={`graph-wrapper ${selectedNode ? 'with-details' : ''}`}>
            <GraphControls
              max_credits={maxCredits}
              credits={user?.user_data?.credits}
              isLowPerformanceMode={isLowPerformanceMode}
              setIsLowPerformanceMode={setIsLowPerformanceMode}
              onExport={handleExport}
              onImport={handleImport}
              graphTitle={graphTitle}
              setGraphTitle={setGraphTitle}
              onSave={handleSave}
              fileInputRef={fileInputRef}
              hasNodes={nodes.length > 0}
            />

            <SavedGraphsList
              isOpen={isSavedGraphsOpen}
              savedGraphs={savedGraphs}
              onToggle={() => setIsSavedGraphsOpen(!isSavedGraphsOpen)}
              onLoad={handleLoadGraph}
              onDelete={handleDeleteGraph}
            />

            <GraphNetwork
              nodes={nodes}
              edges={edges}
              isLowPerformanceMode={isLowPerformanceMode}
              onNodeSelect={setSelectedNode}
              ref={networkRef}
            />
          </main>

          {selectedNode && (
            <aside className={`side-panel ${isSidePanelCollapsed ? 'collapsed' : ''}`}>
              <button 
                className="collapse-toggle"
                onClick={toggleSidePanel}
                aria-label={isSidePanelCollapsed ? "Expand panel" : "Collapse panel"}
              >
                {isSidePanelCollapsed ? '←' : '→'}
              </button>
              <NodeDetails
                node={selectedNode}
                nodeLookup={nodeLookup}
                onClose={() => setSelectedNode(null)}
              />
            </aside>
          )}
        </div>
      </div>
    </div>
  );
};

export default GraphComponent;