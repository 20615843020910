import { CreditCard as CreditCardIcon, Sparkles } from 'lucide-react';

const CreditCard = ({ title, credits, price, sellixId, description }) => {
  return (
    <div className="credit-card">
      <div className="credit-card-content">
        <div className="credit-card-header">
          <CreditCardIcon className="credit-icon" />
          <div className="sparkles-container">
            <Sparkles className="sparkle sparkle-1" />
            <Sparkles className="sparkle sparkle-2" />
            <Sparkles className="sparkle sparkle-3" />
          </div>
        </div>
        
        <h3 className="credit-card-title">{title}</h3>
        
        <div className="credits-display">
          <div className="credits-amount">
            <span className="credits-value">{credits.toLocaleString()}</span>
            <span className="credits-label">SeekCoins</span>
          </div>
          <div className="credits-glow" />
        </div>
        
        <p className="credit-card-description">{description}</p>
        
        <div className="credit-price">
          <span className="credit-currency">$</span>
          <span className="credit-amount">{price.toFixed(2)}</span>
        </div>
        
        <button 
          className="credit-purchase-button" 
          data-sellix-product={sellixId}
        >
          <span>Purchase Credits</span>
          <div className="button-shine" />
        </button>
      </div>
    </div>
  );
};

export default CreditCard;