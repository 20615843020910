import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import { format } from 'date-fns';

const getTimestamp = () => format(new Date(), 'yyyy-MM-dd_HH-mm-ss');

const waitForNetworkStabilization = async (networkRef) => {
  await networkRef.stabilize();
};

const validateCanvas = (networkRef) => {
  const canvas = networkRef.getCanvas();
  if (!canvas) {
    throw new Error('Canvas not found in network');
  }
  return canvas;
};

const validateNetwork = (network) => {
  if (!network.network) {
    throw new Error('Network instance is not properly initialized');
  }
  
  const canvas = network.network.canvas.frame.canvas;
  if (!canvas) {
    throw new Error('Canvas not found in network');
  }
  
  return canvas;
};

const createFilename = (format, rootNode) => {
  const timestamp = getTimestamp();
  const prefix = rootNode ? rootNode.label : 'graph';
  return `${prefix}_${timestamp}.${format}`;
};

const createSVGExport = async (network, rootNode) => {
  try {
    await new Promise(resolve => setTimeout(resolve, 500));
    const canvas = validateNetwork(network);
    const bounds = network.network.getBoundingBox();
    
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    svg.setAttribute('width', canvas.width);
    svg.setAttribute('height', canvas.height);
    svg.setAttribute('viewBox', `${bounds.left} ${bounds.top} ${bounds.right - bounds.left} ${bounds.bottom - bounds.top}`);
    
    const image = document.createElementNS('http://www.w3.org/2000/svg', 'image');
    image.setAttribute('x', bounds.left);
    image.setAttribute('y', bounds.top);
    image.setAttribute('width', bounds.right - bounds.left);
    image.setAttribute('height', bounds.bottom - bounds.top);
    image.setAttribute('href', canvas.toDataURL('image/png'));
    
    svg.appendChild(image);
    
    const svgString = new XMLSerializer().serializeToString(svg);
    const blob = new Blob(
      [`<?xml version="1.0" encoding="UTF-8"?>${svgString}`],
      { type: 'image/svg+xml;charset=utf-8' }
    );
    
    return {
      blob,
      filename: createFilename('svg', rootNode)
    };
  } catch (error) {
    console.error('SVG export error:', error);
    throw error;
  }
};

const createPNGExport = async ({ networkRef, rootNode }) => {
  await waitForNetworkStabilization(networkRef);
  const canvas = validateCanvas(networkRef);
  
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (!blob) {
          reject(new Error('Failed to create PNG blob'));
          return;
        }
        
        resolve({
          blob,
          filename: createFilename('png', rootNode)
        });
      },
      'image/png',
      1.0
    );
  });
};

const createJSONExport = (nodes, edges, nodeLookup, rootNode) => {
  const data = {
    exportDate: new Date().toISOString(),
    rootNode: rootNode?.label || null,
    data: {
      nodes,
      edges,
      lookupData: nodeLookup
    }
  };
  
  const blob = new Blob(
    [JSON.stringify(data, null, 2)],
    { type: 'application/json' }
  );
  
  return {
    blob,
    filename: createFilename('json', rootNode)
  };
};

export const exportGraph = async (network, format, nodes, edges, nodeLookup) => {
  const rootNode = nodes[0] || null;
  let toastId = null;

  try {
    toastId = toast.loading(`Exporting ${format.toUpperCase()}...`);
    
    let result;
    switch (format) {
      case 'svg':
        result = await createSVGExport(network, rootNode);
        break;
        
      case 'png':
        result = await createPNGExport(network, rootNode);
        break;
        
      case 'json':
        result = createJSONExport(nodes, edges, nodeLookup, rootNode);
        break;
        
      default:
        throw new Error(`Unsupported format: ${format}`);
    }
    
    if (!result?.blob) {
      throw new Error('Export failed to generate valid data');
    }
    
    saveAs(result.blob, result.filename);
    toast.update(toastId, {
      render: `Graph exported as ${format.toUpperCase()}`,
      type: 'success',
      isLoading: false,
      autoClose: 3000
    });
    
    return result;
    
  } catch (error) {
    const errorMessage = `Export failed: ${error.message}`;
    console.error(errorMessage, error);
    
    if (toastId) {
      toast.update(toastId, {
        render: errorMessage,
        type: 'error',
        isLoading: false,
        autoClose: 5000
      });
    }
    
    throw error;
  }
};

export const validateImportedData = (data) => {
  if (!data?.data?.nodes?.length || !Array.isArray(data.data.nodes)) {
    throw new Error('Invalid nodes data');
  }
  
  if (!data?.data?.edges?.length || !Array.isArray(data.data.edges)) {
    throw new Error('Invalid edges data');
  }
  
  if (!data?.data?.lookupData || typeof data.data.lookupData !== 'object') {
    throw new Error('Invalid lookup data');
  }
  
  return true;
};