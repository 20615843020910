import { useState, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import supabase from '../supabase';
import { toast } from 'react-toastify';

const ProtectedRoute = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getUser = async () => {
      const { data, error } = await supabase.auth.getUser();
      if (data) {
        setUser(data.user);
      } else {
        setUser(null);
      }
      setLoading(false);
    };
    getUser();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    toast.error("Please login !");
    return <Navigate to="/login" />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
