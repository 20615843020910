import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import { Navigate } from 'react-router-dom';
import NotFound from './components/NotFound';
import Home from './pages/Home';
import Login from './pages/Login';
import Signup from './pages/Signup';
import ResetPassword from './pages/ResetPassword';
import VerifyOtp from './pages/VerifyOtp';
import ForgotPassword from './pages/ForgotPassword';

import Dashboard from './pages/Dashboard/Dashboard';
import GraphComponent from './components/graph/GraphComponent';
import History from './pages/Dashboard/History';
import Profile from './pages/Dashboard/Profile';
import Pricing from './pages/Dashboard/Pricing';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles/App.css';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import ProtectedRoute from './components/ProtectedRoute';
import AdminRoute from './components/AdminRoute';
import { SpeedInsights } from '@vercel/speed-insights/react';
const jsonString = '{"id": "631362553263554576", "created_at": "2019-10-09 07:29:22", "username": "noahrval", "avatar": {"id": "a_eab2e870ce93ceb9091c49e970e0cb01", "link": "https://cdn.discordapp.com/avatars/631362553263554576/a_eab2e870ce93ceb9091c49e970e0cb01", "is_animated": true}, "avatar_decoration": null, "badges": ["HOUSE_BRAVERY"], "premium_type": "None", "accent_color": 11974326, "global_name": "Noah", "banner": {"id": "a_32fb6f46563eaac7a7edfa7f77a54d1c", "link": "https://cdn.discordapp.com/banners/631362553263554576/a_32fb6f46563eaac7a7edfa7f77a54d1c?size=480", "is_animated": true, "color": "#b6b6b6"}}';
const data = JSON.parse(jsonString);


function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Seekbase</title>
        <link rel="icon" href="%PUBLIC_URL%/favicon.ico" type="image/x-icon" />
        <link rel="icon" href="%PUBLIC_URL%/favicon.svg" type="image/svg+xml" />
        <link rel="apple-touch-icon" href="%PUBLIC_URL%/favicon.png" />
        <link rel="stylesheet" href="https://unpkg.com/leaflet/dist/leaflet.css" />
        <script src="https://cdn.sellix.io/static/js/embed.js"></script>
        <script src="https://unpkg.com/leaflet/dist/leaflet.js"></script>
      </Helmet>
      <Router>
        <div className="app">
          <div className="main-content">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route element={<ProtectedRoute />}>
                <Route path="/dashboard" element={<Dashboard />}>
                  <Route index element={<Navigate to="graph" replace />} />
                  <Route path="history" element={<History />} />
                  <Route path="profile" element={<Profile />} />
                  <Route path="price" element={<Pricing />} />
                  <Route path="graph" element={<GraphComponent />} />
                </Route>
              </Route>

              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/verify-otp" element={<VerifyOtp />} />
              <Route path="/reset-password" element={<ResetPassword/>} />
              <Route path="*" element={<NotFound />} />    
              <Route element={<ProtectedRoute />}>
                <Route path="/profile" element={<Profile />} />
              </Route>
            </Routes>
          </div>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
        <SpeedInsights />
        </div>
      </Router>
    </HelmetProvider>
    
  );
}

export default App;
